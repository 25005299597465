import {HTTP} from "./common";

export const PersonApi = {
    list(filter) {
        return HTTP.get(`persons/?name=${filter?filter:""}`).then((response) => {
            return response.data;
        });
    },
    get(id) {
        return HTTP.get(`persons/${id}/`).then((response) => {
            return response.data;
        });
    },
    create(data) {
        return HTTP.post(`persons/`, data).then((response) => {
            return response.data;
        });
    },
    update(data, _id) {
        return HTTP.put(`persons/${_id}/`, data).then((response) => {
            return response.data;
        });
    },

};
