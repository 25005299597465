<template>
  <main>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">

      <div class="d-flex justify-content-start grid gap-3">
        <MultiSelect v-model="filter.manager"
                     name="Менеджер проекта"
                     field_name="fullname"
                     :options="managers.filter(x => x.is_active)"
                     :multi="false"
                     :only_value="true">
        </MultiSelect>
        <MultiSelect v-model="filter.auditor"
                     name="Аудитор"
                     field_name="fullname"
                     :options="managers.filter(x => x.is_active)"
                     :multi="false"
                     :only_value="true">
        </MultiSelect>
        <MultiSelect v-model="order_by"
                     name="Сортировка"
                     :options="orders"
                     :multi="false"
                     :only_value="true">
        </MultiSelect>
        <div class="per-page">
          <select name="" class="select form-control" id="" v-model="size">
            <option v-for="per_page in per_pages" :value="per_page.id" :key="per_page.name">{{ per_page.name }}
            </option>
          </select>
        </div>
        <div class="btn-group" role="group">
          <template v-for="l in layouts">
          <input type="radio" class="btn-check" name="layout" :id="l" autocomplete="off" :value="l" v-model="layout">
          <label class="btn btn-outline-dark" :for="l"><i :class="`bi bi-grid-${l}-gap-fill`"></i></label>
            </template>
        </div>
      </div>

      <div class="text-secondary no-wrap">
        Показано проектов {{ filtered_projects.length }} из {{ all_count }}
      </div>


    </div>
    <draggable v-model="projects" item-key="id" :sort="true" @change="changeSort" class="projects">
      <template #item="{element}">
        <ProjectInfo :project="element" :layout="layout"></ProjectInfo>
      </template>
    </draggable>
    <!--    <div class="projects">-->
    <!--      <ProjectInfo v-for="project in filtered_projects" :project="project"></ProjectInfo>-->
    <!--    </div>-->
    <nav>
      <ul class="pagination justify-content-center fz-9" v-if="pager.length">
        <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
          <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
          <span class="page-link" v-else>{{ p }}</span>
        </li>
      </ul>
    </nav>
  </main>
</template>

<script>
import {ProjectApi} from "@/api/project";
import {UserApi} from "@/api/user";
import ProjectInfo from "@/components/ProjectInfo.vue"
import draggable from 'vuedraggable'

export default {
  name: 'FrontView',
  components: {
    ProjectInfo,
    draggable
  },
  data() {
    return {
      projects: [],
      managers: [],
      filter: {seo: '', manager: '', auditor: ''},
      page: 1,
      all_count: 0,
      layouts: ["3x3", "3x2"],
      per_pages: [
        {id: 10, name: '10'},
        {id: 20, name: '20'},
        {id: 50, name: '50'},
        {id: 100, name: '100'},
        {id: 200, name: '200'},
        {id: "all", name: 'Все'},
      ],
      orders: [
        {id: "sort", name: "По умолчанию"},
        {id: "date_create", name: "Дата создания ↓"},
        {id: "-date_create", name: "Дата создания ↑"},
        {id: "favorite", name: "Избранное сначала"},
      ]
    }
  },
  computed: {
    size: {
      get(){
        return this.$store.getters.user?.settings?.per_page || 20
      },
      set(val){
        this.$store.dispatch("setSettings", {per_page: val})
      }
    },
    order_by: {
      get(){
        return this.$store.getters.user?.settings?.order_by || "sort"
      },
      set(val){
        this.$store.dispatch("setSettings", {order_by: val})
      }
    },
    layout: {
      get(){
        return this.$store.getters.user?.settings?.layout || "3x3"
      },
      set(val){
        this.$store.dispatch("setSettings", {layout: val})
      }
    },
    filters() {
      return this.$store.getters.project_filters || []
    },
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      return Array.from({length: page_count}, (_, i) => i + 1);
    },
    filtered_projects() {
      let favor = this.$store.getters.user.favorites || [];
      let qs = this.projects;
      if (favor.length) {
        qs = qs.map((pr) => {
          if (favor.filter(p => p.id === pr.id).length) pr.sort = -100
          return pr
        })
      }
      return qs.sort((a, b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))
    }
  },
  watch: {
    filters() {
      this.getProjects()
    },
    filter: {
      deep: true,
      handler() {
        this.getProjects();
      }
    },
    page: {
      deep: true,
      handler() {
        this.getProjects();
      }
    },
    size: {
      deep: true,
      handler() {
        this.getProjects();
      }
    },
    order_by: {
      deep: true,
      handler() {
        this.getProjects();
      }
    }
  },
  mounted: function () {
    this.getProjects()
    UserApi.managers().then(response => this.managers = response);
  },
  methods: {
    changeSort(val) {
      let data = {};
      this.projects.forEach((project, index)=>{
        if (index !== project.index){
          data[project.id] = {sort: index}
          project.index = index
        }
      })
      this.$store.dispatch("setSettings", {projects: data})
    },
    getProjects() {
      let filter = JSON.parse(JSON.stringify(this.filter));
      filter['project_type'] = this.filters;
      let $this = this;
      filter = `page=${this.page}&size=${this.size}&order_by=${this.order_by}&query=${JSON.stringify(filter)}`
      ProjectApi.list(filter).then(response => {
        $this.projects = response.results;
        $this.projects = $this.projects.map((obj, index) => ({ ...obj, index: index }))
        $this.all_count = response.count;
      });
    }
  }

}

</script>

