<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Персоны</h2>

    </div>
    <div class="filters d-flex justify-content-between">
      <div role="search">
        <i class="bi bi-search"></i>
        <input type="search" class="form-control" placeholder="Поиск" v-model="search" aria-label="Поиск">
      </div>
      <button @click="empty_person.edit=true;" class="btn btn-sm btn-outline-dark">+ Добавить</button>
    </div>
     <form v-if="empty_person.edit" action="" @submit.prevent="savePerson(empty_person)" class="d-flex create-form mt-2">
      <label for="">Название</label>
      <input v-model="empty_person.name" required type="text" class="form-control">
      <label for="">ID ПланФикс</label>
      <input v-model="empty_person.pf_id" type="text" class="form-control">
      <button type="submit" class="btn btn-sm btn-outline-dark">Сохранить</button>
      <button type="button" @click.prevent="empty_person.edit=false" class="btn btn-sm btn-outline-secondary">Отмена</button>
    </form>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive d-flex" style="height: auto; overflow: visible">
          <table class="table">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col">Название</th>
              <th scope="col">PF ID</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <!--            <tbody>-->
            <template v-for="(person, index) in persons">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ person.name }}</td>
                <td>{{ person.pf_id }}</td>
                <td>
                  <button @click="person.edit = !person.edit" class="btn btn-sm btn-outline-dark"><i
                      class="bi bi-pencil-fill"></i></button>
                </td>
              </tr>
              <tr v-if="person.edit">
                <td></td>
                <td>
                    <input type="text" v-model="person.name" class="form-control">
                </td>
                <td>
                    <input type="text" v-model="person.pf_id" class="form-control">
                </td>
                <td class="align-bottom">
                  <button class="btn btn-sm btn-outline-dark" title="Сохранить" @click="savePerson(person)"><i class="bi bi-check-lg"></i></button>
                  <button class="btn btn-sm btn-outline-secondary" @click="person.edit=false" title="Отмена"><i class="bi bi-x-lg"></i></button>
                </td>
                <td>

                </td>
                <td colspan="2"></td>
              </tr>
            </template>
            <!--            </tbody>-->
          </table>
        </div>
      </div>
    </div>


  </main>
</template>

<script>
import {PersonApi} from "@/api/persons";

export default {
  name: "PersonList",

  data() {
    return {
      persons: [],
      search: "",
      empty_person: {}
    }
  },
  watch: {
    search(val) {
      if (val.length > 2 || !val) {
        this.getList()
      }

    }
  },
  mounted: function () {
    this.getList()
  },
  methods: {
    getList() {
      PersonApi.list(this.search ? this.search : '').then(response => this.persons = response)
    },
    savePerson(person){
      const $this = this;
      let method = person.id?PersonApi.update:PersonApi.create
      method(person, person.id).then(response =>{
        if (person.id){
          $this.persons.slice($this.persons.findIndex(x=>x.id===person.id), 1, response)
        }
        else{
          $this.persons.unshift(response)
        }
        $this.empty_person = {};
        person.edit = false;
      })
    }
  },
}
</script>
<style scoped lang="scss">
.create-form{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  input{
    width: 250px;
  }
}
</style>