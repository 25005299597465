import { HTTP } from './common'

export const VideoApi = {

   list(filter) {
    return HTTP.get(`videos/?${filter}`).then((response) => {
      return response.data;
    });
  },

  update(object) {
    return HTTP.post(`videos/update_tone/`, object).then(response => {
      return response.data
    });
  },


};
