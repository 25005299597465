import { HTTP } from "@/api/common";

export const AuthyApi = {

  list(filters) {
    return HTTP.get(`authy/?`+filters).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`authy/${id}/`).then((response) => {
      return response.data;
    });
  },
  delete(id) {
    return HTTP.delete(`authy/${id}/`).then((response) => {
      return response.data;
    });
  },
  post(data) {
    return HTTP.post(`authy/import_code/`, data).then((response) => {
      return response.data;
    });
  },
  patch(id, data) {
    return HTTP.patch(`authy/${id}/`, data).then((response) => {
      return response.data;
    });
  },
  create(data) {
    return HTTP.post(`authy/`, data).then((response) => {
      return response.data;
    });
  },
  group(id) {
    return HTTP.get(`authy/groups/${id}/`).then((response) => {
      return response.data;
    });
  },
  save_group(id, data) {
    return HTTP.patch(`authy/groups/${id}/`, data).then((response) => {
      return response.data;
    });
  },
  groups() {
    return HTTP.get(`authy/groups/`).then((response) => {
      return response.data;
    });
  },
  add_groups(data) {
    return HTTP.post(`authy/groups/`, data).then((response) => {
      return response.data;
    });
  },
  create_share(data) {
    return HTTP.post(`authy/sharelink_create/`, data).then((response) => {
      return response.data;
    });
  },
  use_share(link) {
    return HTTP.get(`authy/sharelink/?link=${link}`).then((response) => {
      return response.data;
    });
  },

};