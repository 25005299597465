<template>
  <div class="code-wrapper">

    <div class="filter">
      <form action="" @submit.prevent="getCodes" class="w-40 search">
        <i class="bi bi-search show-mob" @click="$event.target.nextElementSibling.classList.remove('hide-mob')"></i>
        <input type="text" v-model="search" class="form-control hide-mob" placeholder="Поиск">
      </form>

      <MultiSelect :class="hide_filter?'hide-mob':''"
                   v-model="f_groups"
                   :options="groups"
                   :multi="true"
                   :name="'Группа'"
                   :required="true"
                   :only_value="true"
      ></MultiSelect>
      <div :class="'per-page'+ (hide_filter?' hide-mob':'')">
        <select name="" class="select form-control" id="" v-model="size">
          <option v-for="per_page in per_pages" :value="per_page.id" :key="per_page.name">{{ per_page.name }}
          </option>
        </select>
      </div>
      <div class="btn-group" role="group">
        <template v-for="l in layouts">
          <input type="radio" class="btn-check" name="layout" :id="l" autocomplete="off" :value="l" v-model="layout">
          <label class="btn btn-outline-dark" :for="l"><i :class="`bi bi-${l}`"></i></label>
        </template>
      </div>
      <button :class="'btn btn-sm btn-outline-dark'+(hide_filter?' hide-mob':'')" @click="edit=!edit">
        <template v-if="!edit"><span>Редактировать порядок</span></template>
        <template v-else><span>Выход из режима</span>
        </template>
      </button>
      <div class="create">
        <AddCode :saveCode="saveCode"></AddCode>
      </div>
      <a href="" @click.prevent="hide_filter=!hide_filter" class="show-mob show-filters w-40">фильтры</a>
    </div>
    <draggable v-if="edit" v-model="codes" item-key="id" @change="changeSort" class="codes editor">
      <template #item="{element}">
        <CodeSmall layout="editor" :code="element" :editGroup="editGroup" :groups="groups" :getCode="getCode"
                   :addToShare="addToShare"
                   :removeCode="removeCode"
                   :saveCode="saveCode" :shares="shares"/>
      </template>
    </draggable>
    <div v-else :class="`codes ${layout}`">
      <template v-for="code in codes" :key="code.id">
        <CodeSmall :layout="layout" :getCode="getCode" :editGroup="editGroup" :code="code" :groups="groups"
                   :addToShare="addToShare"
                   :removeCode="removeCode"
                   :saveCode="saveCode" :shares="shares"/>
      </template>
    </div>
    <button v-if="shares.length" @click.prevent="createShareLink" class="btn btn-outline-dark share-btn"><i
        class="bi bi-share-fill"></i>Поделиться
    </button>
    <nav>
      <ul class="pagination justify-content-center fz-9" v-if="pager.length > 1">
        <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
          <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
          <span class="page-link" v-else>{{ p }}</span>
        </li>
      </ul>
    </nav>
    <modal modal_id="edit_group">
      <template v-slot:header>
        <h2>
          Доступы для группы "{{ edit_group?.name }}"
        </h2>
      </template>
      <template v-slot:body>
        <div style="height: 300px; width: 500px;" v-if="edit_group">
          <MultiSelect v-if="edit_group"
                       v-model="edit_group.users"
                       :options="managers"
                       field_name="fullname"
                       :multi="true"
                       name="Менеджер проекта"
                       :only_value="true"
          ></MultiSelect>
        </div>
      </template>
      <template v-slot:footer>
        <button class="btn btn-sm btn-secondary" @click="edit_group=null;$modal('hide', 'edit_group');">Отмена</button>
        <button class="btn btn-sm btn-dark" @click="saveGroup">Сохранить</button>

      </template>
    </modal>

  </div>
</template>

<script>

import {AuthyApi} from "@/authy/api/authy";
import CodeSmall from "@/authy/includes/CodeSmall.vue";
import AddCode from "@/authy/includes/AddCode.vue";
import {HTTP} from '@/api/common'
import axios from 'axios'
import draggable from 'vuedraggable'
import {UserApi} from "@/api/user";

export default {
  name: 'AuthyFront',
  components: {AddCode, CodeSmall, draggable},
  data() {
    return {
      codes: [],
      groups: [],
      layouts: ["list-task", "grid-3x3-gap"],
      // layout: "grid-3x3-gap",
      size: 50,
      all_count: 0,
      page: 1,
      search: "",
      f_groups: [],
      authySocket: null,
      controller: new AbortController(),
      request: false,
      shares: [],
      edit: false,
      hide_filter: true,
      // edit_group: {},
      managers: [],
      edit_group: null,
      per_pages: [
        {id: 20, name: '20'},
        {id: 50, name: '50'},
        {id: 100, name: '100'},
        {id: 500, name: '500'},
      ]
    }
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
    layout: {
      get() {
        return this.$store.getters.user?.settings?.code_layout || "list-task"
      },
      set(val) {
        this.$store.dispatch("setSettings", {code_layout: val})
      }
    },
  },
  watch: {
    f_groups: {
      handler: function () {
        this.getCodes()
      },
      deep: true
    },
    size() {
      this.getCodes()
    },
    page() {
      this.getCodes()
    },
    search: function (val) {
      if (val.length > 1 || !val.length) {
        this.getCodes()
      }
    },
  },
  mounted() {
    this.getCodes()
    AuthyApi.groups().then(r => this.groups = r.results)
    if (this.$route.query && this.$route.query.token) {
      const $this = this;
      AuthyApi.use_share(this.$route.query.token).then(() => {
        $this.$notify({type: "success", text: "Доступ предоставлен"})
        $this.getCodes();
        history.pushState("", null, location.pathname);
      })
    }
    UserApi.managers().then(response => this.managers = response.filter(x => x.is_active));
    this.connectChatWs()
  },
  unmounted() {
    if (this.authySocket) this.authySocket.close();
  },
  methods: {
    editGroup(group) {
      const $this = this;
      AuthyApi.group(group.id).then((resp) => {
        $this.edit_group = resp;
        $this.$modal("show", "edit_group");
      })
    },
    saveGroup() {
      AuthyApi.save_group(this.edit_group.id, this.edit_group).then(() => this.$modal("hide", "edit_group"))
    },
    connectChatWs() {
      const $this = this;
      const URL = `${location.protocol.includes("https") ? "wss" : "ws"}://${this.$NODE_ENV === "development" ? this.$VUE_APP_WS_URL : location.host}/ws/`
      this.chatSocket = new WebSocket(
          `${URL}authy/${this.$store.getters.user.id}/`
      );

      this.chatSocket.onmessage = function (e) {
        let data = JSON.parse(e.data)
        $this.saveCode([data], false);
      };

      this.chatSocket.onclose = function (e) {
        if ($this.$store.getters.user.id) {
          setTimeout(function () {
            $this.connectChatWs();
          }, 1000);
        }
      };
      this.chatSocket.onerror = () => $this.chatSocket.close();

    },
    sendWs(message) {
      this.waitForConnection(() => this.chatSocket.send(message), 1000);
    },
    waitForConnection(callback, interval) {
      const $this = this;
      if ($this.chatSocket.readyState === 1) {
        callback()
      } else {
        setTimeout(function () {
          $this.waitForConnection(callback, interval);
        }, interval);
      }
    },
    getCode(id) {
      this.sendWs(JSON.stringify({code_id: id}));
    },
    changeSort(val) {
      let data = {};
      this.codes.forEach((code, index) => {
        if (index !== this.$store.getters.user.settings.code[code.id]?.sort) {
          data[code.id] = {sort: index}
        }
      })
      this.$store.dispatch("setSettings", {code: data})
    },
    getCodes() {
      let filters = {size: this.size, page: this.page};
      const $this = this;
      if (this.search) filters.search = this.search;
      filters = new URLSearchParams(filters).toString()
      if (this.f_groups.length) this.f_groups.forEach(x => filters += "&groups=" + x)

      if (this.request) this.cancelSearch();
      const axiosSource = axios.CancelToken.source();
      this.request = {cancel: axiosSource.cancel};
      HTTP.get(`authy/?q=${filters}`, {cancelToken: axiosSource.token}).then(response => {
        $this.codes = response.data.results;
        $this.all_count = response.data.count;
      })

    },
    cancelSearch() {
      this.request.cancel();
      this.request = null;
    },
    addToShare(id) {
      const index = this.shares.findIndex(x => x === id)
      if (index >= 0) this.shares.splice(index, 1)
      else this.shares.push(id);
    },
    saveCode(code, quiet) {
      const $this = this;
      code.forEach(x => {
        let index = this.codes.findIndex(c => c.id === x.id)
        if (index < 0) {
          $this.codes.push(x);
          if (quiet) {
            setTimeout(() => {
              window.location.hash = 'code-' + x.id
            }, 1000);
            $this.$notify({type: "error", text: "Добавлено"})
            $this.$modal('hide', 'add_code')
          }
        } else {
          $this.codes[index] = x;
        }
      })


    },
    removeCode(_id) {
      let index = this.codes.findIndex(x => x.id === _id)
      this.codes.splice(index, 1)
    },

    createShareLink() {
      AuthyApi.create_share({ids: this.shares}).then(response => {
            const url = window.location.origin + '/authy/' + "?token=" + response.link;
            try {
              navigator.clipboard.writeText(url);
              this.$notify({
                type: 'success',
                text: "Ссылка скопирована в буфер обмена"
              });
            } catch ($e) {
              alert("Копирование не доступно. Ссылка для шаринга кодов " + url)
            }
            this.shares = [];

          }
      )
    }
  }
}

</script>
<style lang="scss" scoped>
.code-wrapper {
  max-width: 80vw;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .codes {
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: auto;
    height: 70vh;
    padding: 20px;
    margin: -20px;
    position: relative;

    &.grid-3x3-gap {
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}

.show-mob {
  display: none;
}

.filter {
  display: flex;
  gap: 10px;
  justify-content: start;

  .create {
    margin-left: auto;
    z-index: 15;
  }

  select {
    height: 40px;
  }

  input {
    max-width: 200px;
    height: 40px;

    &::placeholder {
      color: #ccc;
    }
  }


}


.share-btn {
  position: fixed;
  right: 35px;
  border-radius: 100px;
  height: 100px;
  width: 100px;
  z-index: 10;
  background-color: #fff;
  padding: 0;
}


@media (width < 768px) {
  .w-40 {
    width: 40px;
  }
  .hide-mob {
    display: none;
  }
  .show-mob {
    display: block;
  }
  .filter {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    form.search {
      input {
        width: 220px;
        z-index: 100;
        position: absolute;
        top: -3px;
        height: 45px;
      }
    }

    .create {
      position: fixed;
      bottom: 20px;
      right: 20px;
    }
  }
  .code-wrapper {
    max-width: 100vw;
  }
}

:target {
  border-radius: 3px;
  animation: highlight 2000ms ease-out;
}

.code-area.detail:target {
  animation: none;
}

@keyframes highlight {
  0% {
    background-color: rgba(252, 189, 6, 0.5);
  }
  100% {
    background-color: white;
  }
}

</style>

