<template>
 <main class="dashboard">
    <div class="card">
      <div class="card-body text-center m-5">
        <div class="h1">
            <i class="bi bi-wifi-off fz80"></i>
        </div>
        <h1>Ведутся технические работы</h1>
      </div>
    </div>

  </main>
</template>
<script>
export default {
    name: 'InDev',
    data() {
      return {

      }
    },

  }

</script>
<style scoped>
.fz80{
  font-size: 80px;
}
</style>