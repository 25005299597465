<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Организации</h2>

    </div>

    <div class="card">
      <div class="card-body row" v-if="organization && settings">
        <div class="col-sm-8">
          <div class="row">
            <div :class="'col-12'+ (organization.name_error ?' form-error':'')">
              <label>Название оганизации <sup>*</sup></label>
              <input type="text" placeholder="Название оганизации" class="form-control" id="name"
                     v-model="organization.name" required="">
              <div class="error" v-if="organization.name_error">{{ organization.name_error }}</div>
            </div>

          </div>
          <div class="row">

            <div :class="'col-6'+ (organization.site_error ?' form-error':'')">

              <label>Персона <sup>*</sup></label>
              <MultiSelect
                  v-model="organization.person_data"
                  :options="[]"
                  :multi="false"
                  name="Персона"
                  :findFunction="PersonApi.list"
                  :required="true"
                  :only_value="false"
              ></MultiSelect>
              <div class="error" v-if="organization.site_error">{{ organization.site_error }}</div>
            </div>
            <div :class="'col-6'+ (organization.site_error ?' form-error':'')">

              <label>Сайт <sup>*</sup></label>
              <MultiSelect
                  v-model="organization.site"
                  :options="settings.sites"
                  :multi="false"
                  name="Сайт"
                  :required="true"
                  field_name="domain"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="organization.site_error">{{ organization.site_error }}</div>
            </div>
          </div>
          <div class="row">
            <div :class="'col-3'+ (organization.tariff_error ?' form-error':'')">

              <label>Тариф <sup>*</sup></label>
              <MultiSelect
                  v-model="organization.tariff"
                  :options="settings.tariff"
                  :multi="false"
                  name="Тариф"
                  :required="true"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="organization.tariff_error">{{ organization.tariff_error }}</div>
            </div>
            <div :class="'col-3'+ (organization.user_type_error ?' form-error':'')">

              <label>Тип доступа <sup>*</sup></label>
              <MultiSelect
                  v-model="organization.user_type"
                  :options="settings.user_types"
                  :multi="false"
                  name="Тип"
                  :required="true"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="organization.user_type_error">{{ organization.user_type_error }}</div>
            </div>
            <div :class="'col'+ (organization.date_start_error ?' form-error':'')">
              <label>Срок начала договора <sup>*</sup></label>
              <date-picker
                  v-model="organization.date_start"
                  locale="ru"
                  placeholder="Дата начала"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <div class="error" v-if="organization.date_start_error">{{ organization.date_start_error }}</div>
            </div>
            <div :class="'col'+ (organization.date_end_error ?' form-error':'')">
              <label>Дата окончания договора <sup>*</sup></label>
              <date-picker
                  v-model="organization.date_end"
                  locale="ru"
                  placeholder="Дата окончания"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <div class="error" v-if="organization.date_end_error">{{ organization.date_end_error }}</div>
            </div>

          </div>
          <div class="row">
            <div :class="'col'+ (organization.offer_number_error ?' form-error':'')">
              <label>Номер договора <sup>*</sup></label>
              <input class="form-control" type="text" v-model="organization.offer_number">
              <div class="error" v-if="organization.offer_number_error">{{ organization.offer_number_error }}</div>
            </div>
            <div :class="'col'+ (organization.offer_date_error ?' form-error':'')">
              <label>Дата договора <sup>*</sup></label>
              <date-picker
                  v-model="organization.offer_date"
                  locale="ru"
                  placeholder="Дата договора"
                  :clearable="false"
                  format="dd.MM.yyyy"
                  model-type="yyyy-MM-dd"
                  auto-apply
                  :enable-time-picker="false"
              ></date-picker>
              <div class="error" v-if="organization.offer_date_error">{{ organization.offer_date_error }}</div>
            </div>
            <div :class="'col'+ (organization.offer_email_error ?' form-error':'')">
              <label>Email клиента <sup>*</sup></label>
              <input class="form-control" type="text" v-model="organization.offer_email">
              <div class="error" v-if="organization.offer_email_error">{{ organization.offer_email_error }}</div>
            </div>
          </div>
          <div class="row">
            <div :class="'col-12'+ (organization.services_error ?' form-error':'')">
              <label>Название оганизации <sup>*</sup></label>
              <MultiSelect
                  v-model="organization.services"
                  :options="settings.services"
                  :multi="true"
                  name="Услуги"
                  :required="true"
                  :only_value="true"
              ></MultiSelect>
              <div class="error" v-if="organization.services_error">{{ organization.services_error }}</div>
            </div>
          </div>

          <div class="row">
            <button class="btn btn-sm btn-dark" @click="saveForm">Сохранить</button>
          </div>
          <hr>
          <div class="row" v-if="organization.id">
            <div class="col-8">
              <div class="row">
                <div class="col"><h4>Аккаунты</h4></div>
                <form class="col-3 add-account" @submit.prevent="addAccounts">
                  <div>
                    <label>Корень адреса</label> <input class="form-control" required v-model="account.email"
                                                        placeholder="email">
                  </div>
                  <div>
                    <label>Количество</label><input class="form-control" type="number" required v-model="account.count"
                                                    placeholder="Количество">
                  </div>
                  <button class="btn btn-sm btn-outline-dark">Добавить</button>
                  <br>

                </form>

              </div>
            </div>
            <br>
            <table class="table">
              <thead>
              <tr>
                <th class="col">Email</th>
                <th class="col">Активность</th>
                <th class="col">Пароль сменён</th>
                <th class="col">Дата создания</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(account, index) in organization.users">
                <td class="col" v-if="account.id"><a v-if="account.pf_id" target="_blank"
                                                     :href="`https://serm-dr.planfix.ru/task/${account.pf_id}`">{{
                    account.email
                  }}</a>
                  <span class="link-secondary" v-else>{{ account.email }}</span>
                </td>
                <td class="col" v-else>
                  <div :class="(account.email_error ?' form-error':'')">
                    <input placeholder="email" type="email" class="form-control"
                           v-model="account.email">
                    <div class="error" v-if="account.email_error">{{ account.email_error }}</div>
                  </div>
                </td>
                <td class="col"><input type="checkbox" v-model="account.is_active" @change="updateAccount(account)">
                </td>
                <td class="col">
                  {{ account.password_changed ? $moment(account.password_changed).format("DD.MM.YYYY") : "-" }}
                </td>
                <td class="col" v-if="account.id">{{ $moment(account.date_joined).format("DD.MM.YYYY") }}</td>
                <td class="col" v-else>
                  <button class="btn btn-sm btn-outline-dark" @click="organization.users.splice(index, 1)">-</button>
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td>
                  <button @click="organization.users.push({is_active: true})" class="btn btn-sm btn-outline-dark">
                    Добавить
                  </button>
                </td>
              </tr>
              </tbody>

            </table>
            <div class="row">
              <button class="btn btn-sm btn-outline-dark" @click="saveAccounts">Сохранить</button>
            </div>
          </div>
        </div>
        <div class="col">
          <ul>
            <li>
              Стандартно устанавливается Тариф "Внутренний"
            </li>
            <li>
              Типы доступа:
              <ul>
                <li>Свободный - схож по правам с RA,позволяет свободно создавать проекты</li>
                <li>По договору - доступен только просмотр проектов, переданных из RA</li>
              </ul>
            </li>
            <li>
              Доступы к клиентским аккаунтам сохраняются в PlanFix в <a href="https://serm-dr.planfix.ru/task/86719"
                                                                        class="nav-link active" target="_blank">Хранилище
              паролей</a>
            </li>
            <!--            <li>-->
            <!--              <button class="btn btn-sm btn-outline-dark">Получить ссылку</button>-->
            <!--            </li>-->
          </ul>
          <h5 class="border border-primary p-2 rounded" v-if="planfix.length">
            <ul v-if="planfix.length">
              <li v-for="email in planfix">{{ email.email }} - <a :href="email.link"
                                                                  target="_blank">{{ email.link }}</a></li>
            </ul>
          </h5>
          <button @click="getPreview" class="btn btn-outline-dark">Отправить доступы клиенту</button>
        </div>
      </div>
    </div>
    <modal :modal_id="'clientLetter'">
      <template v-slot:header>
        <h5>Предпросмотр письма</h5>
      </template>
      <template v-slot:body>
        <div v-if="letter">
          <p><b>Тема:</b> {{ letter.theme }}</p>
          <p><b>Кому:</b> {{ letter.to }}</p>
          <p><b>Копия:</b> {{ letter.copy }}</p>
          <p v-html="letter.body"></p>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'clientLetter');"
                data-bs-dismiss="modal">Закрыть
        </button>
        <button type="button" class="btn btn-success" v-on:click="sendLetter">Отправить</button>
      </template>
    </modal>


  </main>
</template>

<script>
import {UserApi} from "@/api/user";
import {OrganizationApi} from "@/api/organization";
import validateMixin from "@/mixins/validate"
import moment from "moment/moment";
import {PersonApi} from "@/api/persons";

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default {
  name: "ClientView",
  computed: {
    PersonApi() {
      return PersonApi
    }
  },
  mixins: [validateMixin],

  data() {
    return {
      organization: null,
      managers: [],
      settings: null,
      account: {email: null, count: null},
      validate_name: "organization",
      planfix: [],
      letter: null,
      validate: {
        name: [],
        site: [],
        user_type: [],
        tariff: [],
        offer_number: [],
        offer_email: [],
        offer_date: [],
        services: [{
          func: (o) => {
            return !o.services.length;
          }, error: 'Обязательное поле'
        }],
        date_start: [{
          func: (o) => {
            return !o.date_start;
          }, error: 'Обязательное поле'
        }],
        date_end: [{
          func: (o) => {
            return (moment(o.date_end) - moment(o.date_start)) < 0;
          }, error: 'Дата окончания не может быть меньше даты старта'
        }, {
          func: (o) => {
            return !o.date_end;
          }, error: 'Обязательное поле'
        }]
      },
    }
  },
  beforeMount() {
    if (!this.$store.getters.permission.includes('client_add')) this.$router.push({name: '404'});
  },
  mounted: function () {
    let $this = this;
    OrganizationApi.get(this.$route.params.client_id).then(response => {
      const user = $this.$store.getters.user;
      if ($this.$route.params.client_id && response.managers.indexOf(user.id) < 0 && !this.$store.getters.user.is_superuser) this.$router.push({name: 'clients'});
      $this.organization = response
      $this.clearError()
    })
    OrganizationApi.organization_settings().then(response => this.settings = response)
    UserApi.managers().then(response => {
      this.managers = response.map(x => {
        return {id: x.id, name: x.fullname, post_type: x.post_type, is_active: x.is_active}
      });
    });
  },
  methods: {
    updateAccount(user) {
      OrganizationApi.update_account(this.organization.id, user)
    },
    saveAccounts() {
      let error = false;
      this.organization.users.forEach(user => {
        delete user.email_error;
        if (!user.email) {
          user.email_error = "Обязательное поле";
          error = true;
        }
        let similar = this.organization.users.filter(x => x.email?.toLowerCase().trim() === user.email?.toLowerCase().trim())
        if (similar.length > 1) {
          similar.forEach(k => k.email_error = 'Одинаковые адреса')
          error = true;
        }
      })
      if (!error) {
        let users = this.organization.users.filter(x => !x.id).map(x => {
          return {...x, ...{username: x.email}}
        })

        OrganizationApi.create_account(this.organization.id, users).then(response => this.organization.users = response.users).catch(err => {
          for (let name in err.response.data) {
            this.$notify({
              title: "Ошибка",
              text: `${name}: ${err.response.data[name]}`
            });
            this.organization.users[name + "_error"] = err.response.data[name];
          }
        });


      }
    },
    addAccounts() {
      let start_index = this.organization.users.length;
      const $this = this;
      const site = this.settings.sites.find(x => x.id === this.organization.site).domain;
      const domain = site.substring(site.indexOf('.') + 1);
      [...Array(this.account.count).keys()].forEach(count => {
        let index = start_index + count;
        $this.organization.users.push({
          email: this.account.email + (index ? index : '') + "@" + domain,
          is_active: true
        })
      })
    },
    saveForm() {
      if (!this.validateData()) {
        let $this = this;
        if (this.organization.person_data) this.organization.person = this.organization.person_data.id;
        OrganizationApi.update(this.organization).then(response => {
          $this.organization = response
          this.$notify({
            type: 'success ',
            title: 'Организация добавлена'
          });
          if (!$this.$route.params.id) $this.$router.push(`/clients/${response.id}/`)
        }).catch(err => {
          for (let name in err.response.data) {
            this.$notify({
              title: "Ошибка",
              text: `${name}: ${err.response.data[name]}`
            });
            $this.organization[name + "_error"] = err.response.data[name];
          }
          $this.save_process = false;
        });
      }

    },
    getPreview() {
      if (!this.validateData()) {

        OrganizationApi.preview_mail(this.organization.id).then(response => {
          this.letter = response;
          this.$modal('show', 'clientLetter')
        })
      }

    },
    sendLetter() {
      if (!this.validateData()) {

        OrganizationApi.send_letter(this.organization.id).then(response => {
          this.letter = null;
          this.$modal('hide', 'clientLetter')
          this.$notify({
            type: 'success ',
            title: 'Письмо отправлено'
          });
        })
      }

    },
  },
}
</script>
<style scoped lang="scss">

.col-sm-8 {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.form-error {
  animation: none !important;
  z-index: initial !important;
}

.add-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  input {
    width: 100px;
  }
}

.table {
  margin-top: 30px;
}
</style>

