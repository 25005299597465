<template>
  <div>
    <button class="btn btn-outline-dark btn-sm add-btn" @click.prevent="$modal('show', 'add_code')">
      <span> Добавить</span> <i class="bi bi-plus-lg"></i>
    </button>
    <modal :modal_id="'add_code'">
      <template v-slot:header>
        <h2>Добавить аккаунт</h2>
      </template>

      <template v-slot:body>
        <div class="buttons" v-if="!type">
          <form action="" @submit.prevent="createCode" v-if="code" class="manual">
            <label>Название
              <input type="text" required placeholder="Название" class="form-control" v-model="code.name">
            </label>
            <label>Эмитент
              <input type="text" placeholder="Эмитент" class="form-control" v-model="code.issuer">
            </label>
            <label>Количество цифр
              <input type="number" placeholder="Количество цифр" class="form-control" v-model="code.digits">
            </label>
            <div class="btn-group" role="group">
              <button type="button" @click.prevent="code.has_initial_count=false;code.initial_count=null;"
                      :class="`btn btn-sm btn${code.has_initial_count?'-outline':''}-dark`">TOPT
              </button>
              <button type="button" @click.prevent="code.has_initial_count=true;code.interval=null;"
                      :class="`btn btn-sm btn${!code.has_initial_count?'-outline':''}-dark`">HTOP
              </button>
            </div>
            <label v-if="code.has_initial_count">HTOP
              <input type="number" placeholder="Начальное значение счетчика" class="form-control"
                     v-model="code.initial_count">
            </label>
            <label v-if="!code.has_initial_count">TOPT
              <input type="number" placeholder="Интервал" class="form-control" v-model="code.interval">
            </label>
            <label>Логин
              <input type="text" placeholder="Логин" class="form-control" v-model="code.login">
            </label>
            <label>Пароль
              <div class="password">
                <input class="form-control" autocomplete="off" :type="show_pass?'text':'password'" name="password"
                       v-model="code.pwd" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :style="{color: show_pass?'#000':'#ccc'}"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
              </div>
            </label>

            <label>Описание
              <textarea cols="30" rows="5" v-model="code.description" placeholder="Описание"></textarea>
            </label>
            <button class="btn btn-sm btn-primary" type="submit">Сохранить</button>
          </form>
          <template v-else>
            <button class="btn btn-primary text-white" @click.prevent="type=types.qr"><i class="bi bi-camera"></i>Сканировать
            </button>
            <button class="btn" @click.prevent="type=types.image"><i class="bi bi-card-image"></i>Загрузить изображение
              кода
            </button>
            <button class="btn" @click.prevent="type=types.manual">Добавить вручную</button>
          </template>
        </div>
        <form action="" class="manual" v-if="type===types.qr">
          <qr-stream @detect="onDetect" @decode="onDecode" class="mb">
          </qr-stream>
          <button class="btn btn-sm" @click.prevent="type=null" type="button">Отмена</button>
        </form>
        <form action="" class="manual" v-if="type===types.image">
          <h2>Выберите изображение c QR кодом</h2>

          <qr-dropzone class="dropzone mb" @decode="onDecode">
            Drop image here.
          </qr-dropzone>
          <qr-capture @detect="onDetect" @decode="onDecode" class="mb"></qr-capture>
          <button class="btn btn-sm" @click.prevent="type=null" type="button">Отмена</button>
        </form>
        <form action="" class="manual" @submit.prevent="manualCode" v-if="type===types.manual">
          <h2>Введите код вручную</h2>

          <input required type="text" v-model="manual_code" class="form-control" placeholder="Ключ">
          <button class="btn btn-sm btn-outline-dark" type="submit">Сохранить</button>
          <button class="btn btn-sm" type="button" @click.prevent="type=null">Отмена</button>
        </form>
      </template>

      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'add_code');"
                data-bs-dismiss="modal">Отмена
        </button>
      </template>
    </modal>
  </div>

</template>

<script>
import {QrStream, QrCapture, QrDropzone} from 'vue3-qr-reader';
import {AuthyApi} from "@/authy/api/authy";

export default {
  name: 'AddCode',

  props: {
    saveCode: {type: Function}
  },
  components: {
    QrStream,
    QrCapture,
    QrDropzone
  },

  data() {
    return {
      types: {
        qr: "qr",
        image: "image",
        manual: "manual"
      },
      show_pass: false,
      type: null,
      code: null,
      accept_protocol: ["otpauth:", "optauth-migration:"],
      manual_code: ""
    }
  },
  mounted() {
    // this.$modal("show", "add_code")
  },
  watch: {
    "code.name"(val) {
      if (val && val.includes(":")) {
        let part = val.split(":")
        this.code.issuer = part[0]
        this.code.login = part[1]
      }
    }
  },

  methods: {
    manualCode() {
      this.type = null;
      this.code = {secret: this.manual_code.replaceAll(" ", "").toUpperCase()}
    },
    createCode() {
      const code = JSON.parse(JSON.stringify(this.code))
      this.code = null;
      const $this = this;
      if (code.id) {
        AuthyApi.patch(code.id, code).then(response => {
          response.id = code.id
          $this.type = null;
          $this.saveCode([response])
        });

      } else {
        AuthyApi.post({uri: `${this.accept_protocol[0]}//totp/${code.name}?secret=${code.secret}`}).then((response) => {
          AuthyApi.patch(response[0].id, code).then(r => {
            let code = {...response[0], ...r}
            $this.saveCode([code])
            $this.manual_code = ""
          });

        })
      }
    },
    onDetect(data) {
    },
    onDecode(data) {
      const url = new URL(data)

      if (!this.accept_protocol.includes(url.protocol)) this.$notify({type: "error", text: "Не корректный формат кода"})
      else {
        const $this = this;
        $this.type = null;
        AuthyApi.post({uri: url}).then(response => {
          if (response.length > 1) $this.saveCode(response)
          else {
            $this.code = response[0]
          }
        })

      }
    }
  }
}
</script>
<style lang="scss" scoped>
.buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;

  button {
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 14px;
  }
}

::placeholder {
  color: rgba(139, 139, 139, 1);
}

form {
  margin: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  border: 1px solid;
  border-radius: 20px;

  label {

    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    align-items: self-start;

    input, textarea, .password {
      width: 100%;
    }
  }
}

input::placeholder {
  color: #ccc;
}

.dropzone {
  height: 150px;
  background: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  height: 34px;
}

input::file-selector-button {
  border: 1px solid rgba(139, 139, 139, 1);
  background: rgba(139, 139, 139, 1);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  margin-bottom: 0;
  //height: 35px;
}

@media (width < 768px) {
  .add-btn {
    border-radius: 50px;
    height: 50px;
    width: 50px;
    background: #000;
    color: #fff;
    font-weight: bold;
    font-size: 30px;

    span {
      display: none;
    }
  }
}


</style>

